@import 
  "~normalize.css",
  "~bootstrap/dist/css/bootstrap.min.css",
  "~@blueprintjs/table/lib/css/table.css",
  "~@blueprintjs/core/lib/css/blueprint.css",
  "~@blueprintjs/icons/lib/css/blueprint-icons.css",
  "~@blueprintjs/datetime/lib/css/blueprint-datetime.css",
  "~react-virtualized/styles.css",
  'variables',
  'mixins';

* {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

body {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.selectable {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.bp3-popover-content {
  .big-menu {
    overflow-y: scroll;
    max-height: 600px;
  }
}

.export-content {
  padding: 10px;

  .bp3-button {
    box-shadow: none;
    margin: 0 5px;
  }
}

.bp3-portal {
  z-index: 1000;
}

.lm_dragProxy,
.lm_dropTargetIndicator,
.lm_dragging {
  z-index: 1000 !important;
}

.bp3-spinner {
  height: 100%;
}

.content-wrapper {
  height: 100%;
  overflow-y: auto;
}

.bp3-navbar .bp3-tab {
  line-height: $pt-navbar-height;
}

.bp3-select-popover .bp3-menu {
  min-width: 250px;
  max-height: 300px;
  overflow: auto;
  padding: 0;
}

.edit-popup {
  position: absolute;
  width: 250px;
  top: 40%;
  left: 30%;
  padding: 0 !important;
  background: #fff;
  outline: 1px solid #8d8d8d;
  z-index: 1000;
  padding-top: 35px !important;
  cursor: move;

  h2 {
    text-align: center;
    font-size: 22px;
  }

  &.map {
    top: 8%;
    left: 1%;
  }

  .complete {
    margin-top: 20px;
    width: 100%;
    padding: 20px;
    border: 1px solid lightblue;
    transition: 0.2s ease;

    &:hover {
      background: lightblue;
    }
  }

  .form-container {
    padding: 0 10px;
    margin-top: 10px;
  }

  .edit-popup-close-btn {
    border: none;
    background: none;
    position: absolute;
    right: 0;
    top: 4px;
    z-index: 1001;
  }


  .edit-zone {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    justify-content: center;
    position: relative;
    cursor: default;

    .choice-color {
      width: 100%;
      height: 50px;
      margin: 10px 0;
      cursor: pointer;
    }

    .sketch-picker {
      position: absolute;
      top: -12%;
      right: -91%;
      animation: fadeIn .3s ease forwards;
    }

    .zonestates-type {
      margin: 10px 0;

      input {
        height: 35px;
        margin: 10px 0;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.spinner-container {
  .bp3-spinner {
    margin-right: 8px;
  }

  display: flex;
}

.error-container {
  .bp3-icon {
    color: red;
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
}

.error-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}

.drag-enter {
  background: rgba(0, 0, 0, .1);
}

.active-item {
  background-color: lightblue!important;
}

.default-list {
  list-style: none;
  margin: 0;
  padding: 0;
}