.dialog {
    width: auto;
}

.flex {
    display: flex;

    :global .bp3-form-group {
        min-width: 300px;
        display: flex;
        justify-content: space-between;
    }
}

.column {
        display: flex;
        flex-flow: column;
        &:first-child {
            margin-right: 60px;
        }
}

