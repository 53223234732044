.converters {
    width: 400px;
    padding: 15px;
    background-color: #eee;
}

.select {
    flex-grow: 1;
    & > span {
        width: 100%;
        button {
            width: 100%;
        }
    }
}

.table {
    height: auto;
    width: 100%;
    margin: 15px 0;
    .bp3-table-top-container, .bp3-table-bottom-container {
        width: 100% !important;
    }
}

.tarTableFormTable {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
    td {
        padding: 4px 8px;
    }
}

.centerText {
    text-align: center;
    margin: 15px 0;
}