.PropHeader {
    position: relative;

    h4 {
        padding-top: 16px;
    }

    button {
        position: absolute;
        right: 0;
        top: 12px;
    }

}

.SettingsFooter {
    text-align: right;
    padding-top: 16px;
    padding-bottom: 32px;

    > * {
        margin-left: 20px;
    }
}