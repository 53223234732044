
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1em;
  }
  
  .placeholder {
    display: inline-block;  
    height: 16px;
    background-color: #DDD;
    width: 150px;
  }
  