@import 'src/scss/variables';

.main-dock {
  grid-area: dock;
  height: 100%;
  width: 100%;
}

.main-dock,
.lm_goldenlayout,
.lm_item,
.lm_items,
.lm_item_container,
.lm_content {
  width: 100% !important;
}

.main-dock,
.lm_goldenlayout,
.lm_selected .lm_header {
  background: $pt-app-background-color;
}

.lm_content,
.lm_tab.lm_active {
  background-color: white;
}

.bp3-dark {
  .lm_goldenlayout {
    background: $pt-dark-app-background-color;
  }

  .lm_tab {
    color: $pt-dark-heading-color;
    background-color: $pt-heading-color;
  }

  .lm_tab.lm_active {
    background-color: $pt-dark-link-color;
  }
}

.bp3-alert-contents {
  width: 100%;
}

