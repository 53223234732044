:global .select {
  > span {
    width: inherit;
    > div {
      width: inherit;
      > button {
        width: inherit;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

$label-width: 200px;
$input-width: 250px;
.controls {
  :global .bp3-form-group > .bp3-label  {
    width: $label-width;
  }
  :global .bp3-checkbox {
    width: $label-width - 10px;
  }
  input {
    width: $input-width !important;
  }
  :global .select {
    width: $input-width;
  }
}

.sensor-prototypes {
  margin-left: 24px;
}

.sensor-prototypes--list {
  width: 400px;
  height: 400px;
  padding: 8px;
  background-color: white;
  overflow: auto;
  border: 1px solid lightgray;
}

.sensor-prototypes--label {
  margin-bottom: 12px;
}

.wrapper {
  padding: 16px;
  display: flex;
}

div.dialog {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  width: auto;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2),
    0 18px 46px 6px rgba(16, 22, 26, 0.2);
  background: #ebf1f5;
  padding-bottom: 20px;
  pointer-events: all;
  -webkit-user-select: text;
  user-select: text;
}

.sensors {
  background-color: #fff;
  border: 1px solid gray;
  padding: 1em;
  ul {
    list-style: none;
    padding: 0;
  }
}

.select-wrapper {
  margin-bottom: 1em;
}
