.input-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.5em 0;
  label {
    margin-bottom: 0.25em;
  }
  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }
}
