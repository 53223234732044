.table-container {
    position: relative;
    min-height: 50px;
}

.spinner-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.67);
}

.cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-btn {
    margin-right: 10px;
}

.add-btn {
    display: block;
    margin: 20px 0;
}
