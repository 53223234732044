.container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100%;
}

.main-props-container {
    background-color: lightgray;
    padding: 10px;
    overflow-y: auto;
}

.info-container {
    overflow-y: auto;
    padding: 10px;
}