.showDemounted {
    :global .demounted {
        display: block;
    }
}

.hideDemounted {
    :global .demounted {
        display: none;
    }
}

.tree {
    :global .filtered {
        display: none;
    }
}

.wrapper {
    flex-grow: 1;
}