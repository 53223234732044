.list {
  padding: 1em 0;
}

.resizer {
  width: 100%;
  height: 100%;
}

.ph_objectname {
  background-color: #ddd;
  height: 1em;
  width: 100px;
}

.ph_objecticon {
  background-color: #ddd;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}


.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1em;
}

.placeholder {
  display: inline-block;  
  height: 16px;
  background-color: #DDD;
  width: 150px;
}
